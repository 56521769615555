import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { getClient } from './apollo';
import gql from 'graphql-tag';

export const DEVELOPMENT_URI = 'http://localhost:8083'
export const PRODUCTION_URI = 'https://prod.coinapp.co/manager'
// export const PRODUCTION_URI = 'https://bossapi.coinapp.co'

// const uri = PRODUCTION_URI
export const uri = process.env.NODE_ENV === 'development' ? DEVELOPMENT_URI : PRODUCTION_URI
export const coinBossClient = getClient(uri)

export const collectionByDayFragment = gql`
fragment CollectionByDay on CollectionByDay {
  id
  yyyymmdd
  uid
  count
  collected
  xyoCollected
}
`

export const collectionFragment = gql`
fragment Collection on Collection {
  id
  minedMaphash
  fullMaphash
  latitude
  longitude
  lastMined
  lastMinedYYYYMMDD
  random
  lastMinerUid
  minedAmount
  xyoCollected
  asset
  isBigWin
  isSmallWin
  os
  osVersion
  osDevMode
  sentinels
  altitude
  accuracy
  ibeacons
  appVersion
  speed
  direction
  ip
  adjustmentPower
  recentCount
  payoutCollection
  payoutTier
  auto
  deviceId
  deviceName
  ssid
  mob
  color
  account
  campaign
  tierGroup
  plan
  lonlat
  team
  homeBase
}
`

export const userInfoFragment = gql`
fragment UserInfo on UserInfo {
  id
  lastMaphash
  xyoRedeemed
  xyoPending
  xyoRedeemPending
  xyoDropped
  xyoBossDropped
  xyoCollected
  xyoGifted
  xyoPickedUp
  xyoGeoclaimed
  xyoHodlRewards
  xyoBossBalance
  xyoBossGifted
  xyoEnabled
  hodlLastCollectedYYYYMMDD
  recordVersion
  remineMarker
  recentCount
  color
  referrer
  checkInDate
  created
  appVersion
  referrerUid
  addedToDrip
  mineCountNoSentinel
  ibeacons
  liteOk
  masterOk
  reducedRewards
  allowOffers
  remineCount
  subscriptionInfo
  osDevMode
  ownerUID
  osVersion
  mineCount
  recentMarker
  mineCountSentinel
  direction
  activeSubscription
  activeSubscriptionLock
  subActive
  ip
  lastMinedYYYYMMDD
  verifiedAddressesTotal
  sentinels
  random
  lastMined
  lastSync
  ssid
  os
  deviceId
  deviceIds
  deviceName
  speed
  suspended
  profileMultiplier
  # photoURL
  # email
}
`

export const coinNoteFragment = gql`
fragment CoinNote on CoinNote {
  _id
  uid
  beaconid
  date
  type
  note
  from
  notetaker {
    photoURL
    email
  }
}
`

export const redeemFragment = gql`
fragment Redeem on RedeemRequest {
  id
  wallet
  updated
  canceledDate
  reference
  ownerUID
  created
  confirmation
  shipFullName
  shipStreetAddress
  shipStreetAddress2
  shipCity
  shipState
  shipZipcode
  shipCountry
  shipPhoneNumber
  notes
  sent
  coinType
  status
  contact
  type
  fromLegacy
  imported
  itemId
  quantity
  uid
  state
  xyoCost
  xyoERC20Requested
  email
  timestamp
  ethAddress
  date
  currentTime
  yyyymmdd
  userPhotoUrl
  verification_code
  useCoinbase
  coinbaseEmail
  coinbaseTransactionId
  failedReason
  orderId
  coinbaseInfo {
    status
    description
  }
}
`

const redeemOptionFragment = gql`
  fragment RedeemOption on RedeemOption {
    id
    active
    sortOrder
    variable
    currency
    currencyAmount
    title
    imageUrl
    description
    processingFeeInDollars
    requiresShippingAddress
    requiresWalletAddress
    requiresCoinbaseEmail
    requiresAccountEmail
    activeWhenNoCrypto
    redeemCount
    redeemLimit
    
    shippingSku
    productId
    internationalShippingFee
    variants {
      shippingSku
      productId
      description
      name
    }
  }
`

export const redeemOptionsQuery = gql`
query RedeemOptions {
  redeemOptions {
    ...RedeemOption
  }
}

${redeemOptionFragment}
`

export const redeemOptionQuery = gql`
query RedeemOption ($id: ObjectId!, $pagination: Pagination) {
  redeemOption(id: $id) {
    ...RedeemOption

    actionLogs(pagination: $pagination, sort: { field: "date", order: -1 }) {
      total
      data {
        id
        action
        byUid
        date

        changes {
          ... on RedeemOptionChangeString {
            field
            toString: to
            fromString: from
          }
          ... on RedeemOptionChangeInt {
            field
            toInt: to
            fromInt: from
          }
          ... on RedeemOptionChangeFloat {
            field
            toFloat: to
            fromFloat: from
          }
          ... on RedeemOptionChangeBoolean {
            field
            toBoolean: to
            fromBoolean: from
          }
        }
      }
    }
  }
}

${redeemOptionFragment}
`

export const redeemOptionsSortOrder = gql`
mutation RedeemOptionsSortOrder ($order: [RedeemSortOrder!]!) {
  redeemOptionsSortOrder(order: $order)
}
`

export const updateRedeemOption = gql`
mutation UpdateRedeemOptions ($option: RedeemOptionInput!) {
  updateRedeemOption(option: $option) {
    ...RedeemOption
  }
}

${redeemOptionFragment}
`

export const createRedeemOption = gql`
mutation CreateRedeemOptions ($option: RedeemOptionInput!) {
  createRedeemOption(option: $option) {
    ...RedeemOption
  }
}

${redeemOptionFragment}
`

export const redeemHistoryQuery = gql`
query RedeemHistory($pagination: Pagination, $sort: Sort, $filter: RedeemFilter, $filterAdvanced: RedeemFilterAdvanced) {
  redeemHistory(filter: $filter, filterAdvanced: $filterAdvanced, pagination: $pagination, sort: $sort) {
    total
    page
    data {
      ...Redeem
    }
  }
}

${redeemFragment}
`

export const approveRedeemRequest = gql`
mutation ApproveRedeemRequest($reference: String!) {
  approveRedeemRequest(reference: $reference) {
    ...Redeem
  }
}

${redeemFragment}
`

export const processRedeemRequest = gql`
mutation ProcessRedeemRequest($reference: String!) {
  processRedeemRequest(reference: $reference) {
    ...Redeem
  }
}

${redeemFragment}
`

export const generateCustomTokenDocument = gql`
mutation GenerateCustomToken($uid: String!, $reason: String) {
  generateCustomToken(uid: $uid, reason: $reason)
}
`

export const sendReviewGift = gql`
mutation SendReviewGift($uid: String!, $giftType: String!, $amount: Float!, $message: String!) {
  sendReviewGift(uid: $uid, giftType: $giftType, amount: $amount, message: $message) {
    created
    error
    gift {
      dropUid
      claimed
      claimedDeviceId
      claimedUid
      collected
      collectedTime
    }
  }
}
`

export const resetGifted = gql`
mutation ResetGifted ($uid: String!, $note: String) {
  resetGifted(uid: $uid, note: $note) {
    ...UserInfo
  }
}

${userInfoFragment}
`

export const suspendUser = gql`
mutation SuspendUser ($uid: String!, $reason: String!, $note: String) {
  suspendUser(uid: $uid, reason: $reason, note: $note) {
    ...UserInfo
  }
}

${userInfoFragment}
`

export const unsuspendUser = gql`
mutation UnsuspendUser ($uid: String!, $note: String, $forfeitCoin: Float) {
  unsuspendUser(uid: $uid, note: $note, forfeitCoin: $forfeitCoin) {
    ...UserInfo
  }
}

${userInfoFragment}
`

export const mergeUser = gql`
mutation MergeUser ($toUid: String!, $fromUid: String!, $note: String) {
  mergeUser(toUid: $toUid, fromUid: $fromUid, note: $note) {
    ...UserInfo
  }
}

${userInfoFragment}
`

export const restoreExpiredBalance = gql`
mutation RestoreExpiredBalance ($uid: String!) {
  restoreExpiredBalance(uid: $uid) {
    ...UserInfo
  }
}

${userInfoFragment}
`

export const updateSubscriptionConfig = gql`
mutation UpdateSubscriptionConfig ($uid: String!, $config: SubscriptionConfigInput!) {
  updateSubscriptionConfig(uid: $uid, config: $config) {
    ...UserInfo
  }
}

${userInfoFragment}
`

export const updateUserConfig = gql`
mutation UpdateUserConfig ($uid: String!, $config: UserConfigInput!) {
  updateUserConfig(uid: $uid, config: $config) {
    ...UserInfo
  }
}

${userInfoFragment}
`

export const lockAsBasic = gql`
mutation lockAsBasic($uid: String!, $locked: Boolean!, $reason: String) {
  lockAsBasic(uid: $uid, locked: $locked, reason: $reason) {
    ...UserInfo
  }
}

${userInfoFragment}
`

export const createNoteDoc = gql`
mutation CreateNote ($input: CoinNoteInput!) {
  createNote (input: $input) {
    ...CoinNote
  }
}

${coinNoteFragment}
`

export const getNotesByUserIdDoc = gql`
query getNotesByUserId ($uid: String!) {
  getNotesByUserId (uid: $uid) {
    ...CoinNote
  }
}

${coinNoteFragment}
`

export const getNotesByBeaconIdDoc = gql`
query getNotesByBeaconId ($beaconid: String!) {
  getNotesByBeaconId (beaconid: $beaconid) {
    ...CoinNote
  }
}

${coinNoteFragment}
`

export const denyRedeemRequest = gql`
mutation DenyRedeemRequest($reference: String!, $reason: String) {
  denyRedeemRequest(reference: $reference, reason: $reason) {
    ...Redeem
  }
}
${redeemFragment}
`

export const refundRedeemRequest = gql`
mutation RefundRedeemRequest($reference: String!, $reason: String) {
  refundRedeemRequest(reference: $reference, reason: $reason) {
    ...Redeem
  }
}
${redeemFragment}
`

export const resendVerifyRedeemRequest = gql`
mutation ResendVerifyRedeemRequest($reference: String!) {
  resendVerifyRedeemRequest(reference: $reference) {
    ...Redeem
  }
}

${redeemFragment}
`

export const resendVerifyAddressHistoryRequest = gql`
mutation ResendVerifyAddressHistoryRequest($id: String!) {
  resendVerifyAddressHistoryRequest(id: $id) {
    type
  }
}
`

export const cancelRedeemRequest = gql`
mutation CancelRedeemRequest($reference: String!, $reason: String) {
  cancelRedeemRequest(reference: $reference, reason: $reason) {
    ...Redeem
  }
}

${redeemFragment}
`

export const addressHistoryQuery = gql`
query AddressHistory($uid: String, $pagination: Pagination, $sort: Sort) {
  addressHistory(uid: $uid, pagination: $pagination, sort: $sort) {
    total
    page
    last
    data {
      id
      uid
      ip
      date
      type
      shipFullName
      shipStreetAddress
      shipStreetAddress2
      shipCity
      shipState
      shipZipcode
      shipCountry
      shipPhoneNumber
      ethAddress
      deviceId
      state
      verified
      verification_code
    }
  }
}
`

export const getActiveMaphashesPageDoc = gql`
query getActiveMaphashesPage (
  $filter: ActiveMaphashesFilter
  $pagination: Pagination
) {
  getActiveMaphashesPage (
    filter: $filter
    pagination: $pagination
  ) {
    total
    data {
      _id
      lastMinerUid
      minedMaphash
      fullMaphash
      deviceId
      color
      expires
      lastMined
      latitude
      longitude
      lonlat
      recentDevices
    }
  }
}
`

export const collectionsAdminDoc = gql`
query collectionsAdmin (
  $filter: CollectionFilterAdmin
  $pagination: Pagination
  $sort: Sort
) {
  collectionsAdmin(
    filter: $filter
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...Collection
    }
    total
    page
  }
}

${collectionFragment}
`
export const collectionsByDayAdmin = gql`
query collectionsByDayAdmin(
  $uid: String!
  $start: Int!
  $end: Int
) {
  collectionsByDayAdmin(
    uid: $uid
    start: $start
    end: $end
  ) {
    ...CollectionByDay
  }
}

${collectionByDayFragment}
`

export const UpdateRedeemRequest = gql`
mutation UpdateRedeemRequest ($reference: String!, $reason: String, $update: RedeemRequestUpdate) {
  updateRedeemRequest(
    reference: $reference
    reason: $reason 
    update: $update 
  ) {
    id
    shipCountry
  }
}
`

export const collectionsDailyStatsAdmin = gql`
query  collectionsDailyStatsAdmin(
  $uid: String!
  $pagination: Pagination
  $sort: Sort
) {
  collectionsDailyStatsAdmin(
    uid: $uid
    pagination: $pagination
    sort: $sort
  ) {
    total
    data {
      ...CollectionByDay
    }
  }
}
`

export const reimportUser = gql`
mutation ReimportUser ($uid: String!, $email: String!, $note: String) {
  reimportUser(uid: $uid, email: $email, note: $note) {
    resetPasswordSent
    userInfo {
      ...UserInfo
    }
  }
}

${userInfoFragment}
`

export const deleteUserAdmin = gql`
mutation DeleteUserAdmin ($uid: String!, $note: String) {
  deleteUserAdmin(uid: $uid, note: $note) {
    ...UserInfo
  }
}

${userInfoFragment}
`

export const BossProvider = ({ children }) => {
  return (
    <ApolloProvider client={coinBossClient}>
      {children}
    </ApolloProvider>
  )
}
